<template lang="pug">
  div#app
    div#header
      comp-header
    div#content
      div#play-left(v-if="isAdmin === 1")
        comp-player(ref="player")
      div#left.column(v-if="isAdmin === 0")
        comp-screen-share
        comp-live-setting
      div#center(v-if="isAdmin === 0")
        comp-live-stream
      div#right.column
        comp-participants
        comp-chat
    room-device-dialog(ref="roomDeviceDialog" @nextStep="showBeautyPresettingDialog" v-if="isAdmin === 0")
    room-beauty-dialog(ref="roomBeautyDialog" v-if="isAdmin === 0")
</template>

<script>
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import {
  SET_APP_INFO,
  SET_ROOM_ID,
  UPDATE_ROOM_NAME,
  UPDATE_USER_INFO,
  UPDATE_LIVE_STAGE,
  SET_PLAYER_DOMAIN,
  UPDATE_PLAY_STATE,
} from 'constants/mutation-types';
import {
    // sdkAppId,
    // secretKey,
  expireTime,
  anchorUserInfo,
  roomInfo,
  playerDomain,
} from '@/config/basic-info-config';
import compHeader from '@/components/comp-header';
import compScreenShare from '@/components/comp-screen-share';
import compLiveSetting from '@/components/comp-live-setting/index.vue';
import compLiveStream from '@/components/comp-live-stream';
import compParticipants from '@/components/comp-participants';
import compChat from '@/components/comp-chat';
import roomDeviceDialog from '@/components/comp-pre-setting/room-device-dialog.vue';
import roomBeautyDialog from '@/components/comp-pre-setting/room-beauty-dialog.vue';
import compPlayer from '@/components/comp-player';
import { LIVE_STAGE, PLAY_STATE } from 'constants/room';
export default {
  name: 'App',
  data() {
    return {
        goodsId: 0,
        userId: 0,
        userName: '',
        userAvatar: '',
        backUrl: '',
        isAdmin: 0,
        liveAdminId: 0,
        teacherId: 0,
        sdkAppId: 0,
        secretKey: '',
        // userSig: '',
        // shareUserSig: '',
    };
  },
  components: {
    compHeader,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compParticipants,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
    compPlayer,
  },
  methods: {
    // 显示设置预设置弹窗
    showDevicePresettingDialog() {
      this.$refs.roomDeviceDialog.handleShowDeviceDialog();
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog() {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog();
    },
    // 设置TUIPusher配置信息
    handlePusherInfo() {
      if (this.sdkAppId === '' || this.secretKey === '') {
            this.back('没有配置直播参数，跳转至原网站?');
        // alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/basic-info-config.js`);
      }
      let sdkAppId = this.sdkAppId;
      // let userSig = this.userSig;
      // let shareUserSig = this.shareUserSig;
      const generator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, expireTime);
      const userSig = generator.genTestUserSig(anchorUserInfo.userId);
      const shareUserSig = generator.genTestUserSig(`share_${anchorUserInfo.userId}`);
      this.$store.commit(SET_APP_INFO, {
        sdkAppId,
        userSig,
        shareUserSig,
      });
      this.$store.commit(SET_ROOM_ID, roomInfo.roomId);
      this.$store.commit(UPDATE_ROOM_NAME, roomInfo.roomName);
      this.$store.commit(UPDATE_USER_INFO, {
        userId: anchorUserInfo.userId,
        userName: anchorUserInfo.userName,
        userAvatar: anchorUserInfo.userAvatar,
        goodsId: this.goodsId,
        isAdmin: this.isAdmin,
        liveAdminId: this.liveAdminId,
      });
      this.$store.commit(SET_PLAYER_DOMAIN, playerDomain);
      this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.NOT_STARTED);
      this.$store.commit(UPDATE_PLAY_STATE, PLAY_STATE.PAUSED);
    },
    // 退出直播间
    async handleExit() {
      // 处理退出直播间
        window.location.href = this.backUrl;
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
        window.location.href = this.backUrl;
    },
    async getUserInfo() {
        if (this.$cookies.get('Authentication') == null) {
            this.back('您没有登录，跳转至原网站?');
        }
        // 获取用户信息
        await this.$api.user.getUserInfo({}).then(res => {
            if (res.errorCode == '0000') {
                console.log(res);
                anchorUserInfo.userId = res.data.userId;
                anchorUserInfo.userName = res.data.realName;
                if (res.data.headUrl) {
                    anchorUserInfo.userAvatar = res.data.headUrl;
                }
                console.log(anchorUserInfo);
            } else {
                this.$message.error(res.errorMsg);
                this.back('您没有登录，跳转至原网站?');
            }
        }).catch(() => {
        });
    },
    getQueryString(name) {
        // const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    async getAreaData(domain) {
        // 获取站点信息
		let dostr = domain;
		dostr = dostr.replace('net.cn', 'cn');
		// dostr = dostr.replace('net', 'cn');
		// dostr = dostr.replace('com.cn', 'cn');
		// dostr = dostr.replace('com', 'cn');
        await this.$api.user.getAreaData({ domain: dostr}).then(res => {
            if (res.errorCode == '0000') {
                console.log(res);
                let baseArr = domain.split(".");
                let first = baseArr.shift();
                baseArr.unshift(first, "api");
                let api = `${baseArr.join(".")}/api.php?s=web`;
                this.$baseUrl = domain;
                this.$handleUrl.axios.defaults.baseURL = `${window.location.protocol}//${api}`;
            } else {
                this.$message.error(res.errorMsg);
                this.back('站点不存在，跳转至原网站?');
            }
        }).catch(() => {
        });
    },
    async getCourseInfo(courseId) {
        // 获取课程信息
        console.log('获取课程信息');
        await this.$api.user.getLiveInfo({ courseId: courseId, type: 1}).then(res => {
            console.log(res);
            if (res.errorCode == '0000') {
                if (res.data.livestatus == 2) {
                    console.log('直播结束');
                    this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.ENDED);
                    this.$eventBus.$emit('exit');
                } else {
                    if (res.data.isAdmin == 0) {
                        this.showDevicePresettingDialog();
                    }
                    this.isAdmin = res.data.isAdmin;
                    this.goodsId = courseId;
                    roomInfo.roomId = parseInt(courseId.substring(courseId.length - 7));
                    roomInfo.roomName = res.data.name;
                    this.teacherId = res.data.teacherId;
                    this.liveAdminId = res.data.liveAdminId;
                }
            } else {
                this.$message.error(res.errorMsg);
                this.back('直播课程不存在，跳转至原网站?');
                this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.ENDED);
                this.$eventBus.$emit('exit');
                this.$refs.roomDeviceDialog.handleClose();
            }
        }).catch(() => {
        });
    },
    async getTencent() {
        await this.$api.seetting.getTencent({}).then(res => {
            if (res.errorCode == '0000') {
                this.sdkAppId = parseInt(res.data.SDKAppID);
                this.secretKey = res.data.secretKey;
                // this.shareUserSig = res.data.shareUserSig;
            } else {
                this.back(res.errorMsg);
            }
        }).catch(() => {
        });
    },
    back(msg) {
        // 跳转至原网站
        let _this = this;
        this.$alert(msg, '提示', {}).then(() => {
                window.location.href = _this.backUrl;
        })
        .catch(() => {});
    },
    setAxiosBaseUrl() {
        let baseArr = this.$baseUrl.split(".");
        let first = baseArr.shift();
        baseArr.unshift(first, "api");
        let api = `${baseArr.join(".")}/api.php?s=web`;
        this.$handleUrl.axios.defaults.baseURL = `${window.location.protocol}//${api}`;
    },
    },
  async created() {
      this.setAxiosBaseUrl();
      this.backUrl = this.getQueryString('backurl');
      if (!this.backUrl || this.backUrl == '') {
          this.backUrl = `http://${this.$baseUrl}`;
      }
      let domain = this.getQueryString('domain');
      if (domain) {
          await this.getAreaData(domain);
      }
      let token = this.getQueryString('token');
      if (token) {
          this.$cookies.set('Authentication', token);
      }
      await this.getUserInfo();
      let courseId = this.getQueryString('courseId');
      if (!courseId) {
          // 课程编号不存在，跳转
          this.back('直播课程不存在，跳转至原网站?');
          this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.ENDED);
          this.$eventBus.$emit('exit');
      }
      await this.getCourseInfo(courseId);
      await this.getTencent();
         this.handlePusherInfo();
         this.$eventBus.$on('exit', this.handleExit);
         this.$eventBus.$on('logout', this.handleLogout);
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
  },
};
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100%
  height 100%
  position relative
  color $fontColor
  div#header
    width 100%
    height 50px
    background-color $backgroundColor
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
  div#content
    position absolute
    top 50px
    width 100%
    left 0
    bottom 0
    display flex
    background-color $backgroundColor
    div#left
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor
    div#play-left
      width 80% !important
      height 100% !important
      max-width 80% !important
      flex-grow 1 !important
      background-color $backgroundColor !important
      .stream
        width 100%
        height 100%
          >>> .vcp-bigplay
            display none
    div#center
      height 100%
      flex-grow 1
    div#right
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor
    .column
      padding 8px
      display flex
      flex-direction column
      > div:not(:first-child)
        margin-top 8px
      > div:last-child
        flex-grow 1
</style>
