<template lang="pug">
  item-card.card-container(:title="$t('Participants') + ' (' + (memberList.length>0 ? memberList.length-1:0 )+'人)'" )
    template
      div.mute-status-container
      div.mute-action
        div.mute-status-all(@click="toggleMuteStatusAll()") 全部禁言
          el-tooltip(class="item" effect="dark" content="全部禁言" placement="top")
            svg-icon(icon-name="info" v-show="!muteStatusAll")
          el-tooltip(class="item" effect="dark" content="全部解禁" placement="top")
            svg-icon(icon-name="info-forbidden" v-show="muteStatusAll")
        slot
        a.sign.sign-up(show="sign_show" v-if="sign_flag" @click="launchSign()") {{sign}}
        a.sign.sign-out(show="sign_show" v-else) {{sign}}
      slot
      div.watch-list-content
        div.watch-list-member
          div.user-item(v-for="member, index in memberList")
            //- 显示用户头像和昵称信息
            div.user-info
              img.user-avatar(:src="getUserAvatar(member)")
              el-tooltip(
                class="item"
                :content="getUserNick(member)"
                placement="bottom"
                visible-arrow=false
              )
                span.user-name {{getUserNick(member)}}
            //- 显示用户是否被禁言状态
            div.user-state(v-if="member.userID !== userInfo.userId")
              span.icon(@click="toggleMuteStatus(member)") 禁言
                el-tooltip(class="item" effect="dark" :content="$t('Mute')" placement="top")
                  svg-icon(icon-name="info" v-show="!member.isMuted")
                el-tooltip(class="item" effect="dark" :content="$t('Unmute')" placement="top")
                  svg-icon(icon-name="info-forbidden" v-show="member.isMuted")
</template>

<script>
import img from 'assets/img/avatar.png';
import itemCard from '@/components/common/item-card';
import { mapState } from 'vuex';
import {
  UPDATE_METE_STATUS,
} from 'constants/mutation-types';
export default {
  name: 'compParticipants',
  components: {
    itemCard,
  },
  data() {
    return {
      url: img,
      title: "全部禁言",
      sign: '点名',
      sign_show: true,
      sign_flag: true,
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      memberList: 'memberList',
      muteStatusAll: 'muteStatusAll',
    }),
  },
  methods: {
    // 获取用户头像
    getUserAvatar(item) {
      return item.avatar ? item.avatar : img;
    },
    // 获取用户昵称
    getUserNick(item) {
      return item.nick ? item.nick : item.userID;
    },
    // 切换禁言状态
    toggleMuteStatus(item) {
      const muteTime = item.isMuted ? 0 : 7 * 24 * 60 * 60;
      this.$eventBus.$emit('tim:setGroupMemberMuteTime', {
        userID: item.userID,
        muteTime,
      });
    },
    // 全部禁言
    toggleMuteStatusAll() {
        console.log(`禁言状态${this.muteStatusAll}`);
        let _this = this;
        this.memberList.forEach(function (item) {
            console.log(item);
            if (item.userID !== _this.userInfo.userId && item.userID !== _this.userInfo.liveAdminId) {
                _this.toggleMuteStatus(item);
            }
        });
        if (this.muteStatusAll) {
            this.$store.commit(UPDATE_METE_STATUS, false);
        } else {
            this.$store.commit(UPDATE_METE_STATUS, true);
        }
    },
    // 点名
    launchSign() {
      this.sign_flag = false;
      let _this = this;
         this.$api.user.launchSign({courseId: this.userInfo.goodsId}).then(res => {
            if (res.errorCode == '0000') {
                setTimeout(function () {
                    _this.sign_flag = true;
                }, 90 * 1000);
              this.$eventBus.$emit('tim:setCall', {signid: res.data.sign});
            } else {
                this.$alert(res.errorMsg, '提示', {}).then(() => {});
            }
        }).catch(() => {
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .card-container
    width 100%
    height 36%
    display flex
    flex-direction column
    .mute-action
      width 100%
      .sign
        display block
        width 60px
        float right
        padding 10px
        font-size 14px
        border-radius 4px
        color #FFF
      .sign-up
        background-color #006EFF
      .sign-out
        background-color #c8c9cc
      .mute-status-all
        width 120px
        float left
        line-height 40px
        .svg-icon
          vertical-align -0.5rem
  .watch-list-content
    flex-grow 1
    width 100%
    margin 10px 0
    height 40%
    border-radius 10px
    padding 0 14px;
  .watch-list-member
    color $fontColor
    width 100%
    height 100%
    display flex
    flex-direction column
    overflow auto
    font-size 14px
    .user-item
      display flex
      flex-direction row
      margin 0 0 8px 0
      justify-content space-between
      align-items center
      width 100%
      .user-info
        display flex
        .user-avatar
          width 24px
          height 24px
          border-radius 50%
          margin 0 10px 0 0
          display inline-block
          vertical-align middle
          object-fit cover
        .user-name
          display inline-block
          max-width 210px
          height 24px
          line-height 24px
          text-align left
          white-space nowrap
          width 100%
          overflow hidden
          text-overflow ellipsis
      .user-state
        height 20px
        .icon
          display inline-block
          width 60px
          height 20px
          vertical-align middle
          line-height 24px
          cursor pointer
          .svg-icon
            vertical-align -0.5rem
</style>

<i18n>
{
    "en": {
        "Participants": "Participants",
    "Mute": "Mute",
    "Unmute": "Unmute"
    },
    "zh": {
        "Participants": "在线观众",
    "Mute": "禁言",
    "Unmute": "取消禁言"
    }
}
</i18n>
