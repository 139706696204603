import { fetch} from '@/utils/http.js';
// import { fetch, post } from '@/utils/http.js';

function getUserInfo(param) {
    return new Promise((resolve, reject) => {
        fetch('/user/getById', {
            params: param
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
}
function getAreaData(param) {
    return new Promise((resolve, reject) => {
        fetch('/area/getAreaData', {
            params: param
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
}
function getLiveInfo(param) {
	return new Promise((resolve, reject) => {
		fetch('/lives/getLiveInfo', {
			params: param
		}).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err);
		});
	});
}
/**
 * 结束直播
 */
function endLive(param) {
	return new Promise((resolve, reject) => {
		fetch('/lives/endLive', {
			params: param
		}).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err);
		});
	});
}
// 发起签到
function launchSign(param) {
	return new Promise((resolve, reject) => {
		fetch('/sign/launchSign', {
			params: param
		}).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err);
		});
	});
}

export default {
	getUserInfo,
	getAreaData,
	getLiveInfo,
	endLive,
	launchSign,
};
